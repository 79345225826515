<template>
  <div>
    <v-icon
      @click="$router.push({ name: 'homepage' })"
    >
      mdi-home
    </v-icon>
    Stránka nenalezena
  </div>
</template>

<script>
export default {
  name: '404'
}
</script>

<style scoped>

</style>
